import { render, staticRenderFns } from "./warehousinghistory.vue?vue&type=template&id=0088c7b6&"
import script from "./warehousinghistory.vue?vue&type=script&lang=js&"
export * from "./warehousinghistory.vue?vue&type=script&lang=js&"
import style0 from "./warehousinghistory.vue?vue&type=style&index=0&id=0088c7b6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports