<template>
    <el-row>
      <el-col :span="24">
        <el-card class="box-card">
        <div slot="header" class="clearfix">
            <el-row :gutter="20">
                <el-col :span="6">
                    <div class="demo-input-suffix">
                        <el-input placeholder="请输入需要查询入库物品的名称" v-model="searchdrugdatatext" @input="searchhistoryInput" @blur="searchhistoryblur">
                        <template slot="prepend">物品</template>
                        </el-input>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-table
          @selection-change="handleChangedrugdata"
          highlight-current-row
          :cell-style="{padding:'0px','text-align':'center'}"
          :data="items"
          class="drugdata"
          style="width: 100%; margin-bottom: 20px;font-size: 12px;"
          :header-cell-style="{background:'#f5f5f5','text-align':'center'}"
          row-key="_id"
          border
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="type" label="类型" fixed="left">
            <template slot-scope="scope">
              {{ getLabel(scope.row.type) }}
            </template>
          </el-table-column>
          <el-table-column prop="fullName" show-overflow-tooltip label="名称" width="140" fixed="left"></el-table-column>
          <el-table-column prop="manufacturer" show-overflow-tooltip label="生成厂商" width="140"></el-table-column>
          <el-table-column prop="displayspec" show-overflow-tooltip label="规格" width="120"></el-table-column>
          <el-table-column prop="Creator" show-overflow-tooltip label="制单人" width="120"></el-table-column>
          <el-table-column prop="procurenum" label="采购数量" width="110"></el-table-column>
          <el-table-column prop="packageprice" label="采购单价" width="110"></el-table-column>
          <el-table-column prop="GenerationDate" label="生产日期" :formatter="formatTime" width="100"></el-table-column>
          <el-table-column prop="ExpirationDate" label="过期日期" :formatter="formatTime" width="100"></el-table-column>
          <el-table-column prop="updatedAt" label="采购日期" width="110" :formatter="formatTime"></el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button style="margin: 5px;" v-debounce size="mini" type="danger" @click="deleteWarehousingdrugdatahistory(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex aligncenter justifybetween">
          <div>
            <el-pagination
              background
              @current-change="handlePageChange"
              @size-change="handlePageSizeChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 40]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </el-card>
      </el-col>
    </el-row>
    </template>
    <script>
    export default {
      data() {
        return {
          dialogTableVisible: false,
          editdialogFormVisible: false,
          dialogFormVisible: false,
          drugdataFormVisible: false,
          drugdataForm:{},
          formLabelWidth: "120px",
          currentPage: 1, // 当前页码
          pageSize: 20, // 每页数据量
          total: 0, // 总数据量
          items: [],
          type:1,
          typename:"zhongxichengyao",
          drugdatacurrentPage:1,
          drugdatapageSize:20,
          drugdatatotal:0,
          zhongxichengyao: [],
          zhongyao: [],
          supplieroptions: [],
          Changedrugdata: [],
          timeslot:"",
          search: "",
          searchdrug: "",
          searchdate: "",
          searchsupplier: "",
          searchdrugdatatext:"",
          drugdatarules: {
              type: [
                  { required: true, message: '请选择商品类型', trigger: 'change' }
              ],
              dismounting: [
                  { required: true, message: '请选择是否可以拆分', trigger: 'change' }
              ],
              fullName: [
                  { required: true, message: '请输入商品名称', trigger: 'blur' },
              ],
              packageUnit: [
                  { required: true, message: '请选择单位，或者输入', trigger: 'change' }
              ],
              packageprice:[
                  { type: 'number', required: true, message: '请输入价格', trigger: 'blur' }
              ],
              procurenum:[
                  { type: 'number', required: true, message: '请输入采购数量', trigger: 'blur' }
              ],
              sellprice:[
                  { type: 'number', required: true, message: '请输入销售单价', trigger: 'blur' }
              ]
          },
          dismountingoptions: [{
              value: 0,
              label: '不能'
          },{
              value: 1,
              label: '能'
          }],
          goodstypeoptions: [{
            value: 0,
            label: '套餐'
          }, {
            value: 1,
            label: '中西成药'
          }, {
            value: 2,
            label: '中药'
          }, {
            value: 3,
            label: '商品'
          }, {
            value: 4,
            label: '治疗'
          },  {
            value: 5,
            label: '理疗'
          },   {
            value: 6,
            label: '物资'
          }, {
            value: 7,
            label: '其它'
          }],
          options: [{
              value: '处方',
              label: '处方'
            }, {
              value: '非处方',
              label: '非处方'
          }],
          usageoptions:[
              { value: '口服', label: '口服' },
              { value: '含服', label: '含服' },
              { value: '嚼服', label: '嚼服' },
              { value: '晨服', label: '晨服' },
              { value: '餐前服', label: '餐前服' },
              { value: '餐中服', label: '餐中服' },
              { value: '餐后服', label: '餐后服' },
              { value: '睡前服', label: '睡前服' },
              { value: '静脉滴注', label: '静脉滴注' },
              { value: '静脉注射', label: '静脉注射' },
              { value: '肌内注射', label: '肌内注射' },
              { value: '腔内注射', label: '腔内注射' },
              { value: '雾化吸入', label: '雾化吸入' },
              { value: '皮下注射', label: '皮下注射' },
              { value: '皮内注射', label: '皮内注射' },
              { value: '穴位注射', label: '穴位注射' },
              { value: '直肠滴注', label: '直肠滴注' },
              { value: '局部注射', label: '局部注射' },
              { value: '局部麻醉', label: '局部麻醉' },
              { value: '超声透药', label: '超声透药' },
              { value: '溶媒用', label: '溶媒用' },
              { value: '外用', label: '外用' },
              { value: '滴眼', label: '滴眼' },
              { value: '滴鼻', label: '滴鼻' },
              { value: '滴耳', label: '滴耳' },
              { value: '口腔喷入', label: '口腔喷入' },
              { value: '鼻腔喷入', label: '鼻腔喷入' },
              { value: '含漱', label: '含漱' },
              { value: '涂抹', label: '涂抹' },
              { value: '塞肛', label: '塞肛' },
              { value: '直肠给药', label: '直肠给药' },
              { value: '阴道给药', label: '阴道给药' }
          ],
          freqselect:[
              { frequency: '每天1次', multiplier: 1, name: 'qd' },
              { frequency: '每天2次', multiplier: 2, name: 'bid' },
              { frequency: '每天3次', multiplier: 3, name: 'tid' },
              { frequency: '每天4次', multiplier: 4, name: 'qid' },
              { frequency: '隔日1次', multiplier: 0.5, name: 'qod' },
              { frequency: '每周1次', multiplier: 1 / 7, name: 'qw' },
              { frequency: '每周2次', multiplier: 2 / 7, name: 'biw' },
              { frequency: '每2小时1次', multiplier: 12, name: 'q2h' },
              { frequency: '每4小时1次', multiplier: 6, name: 'q4h' },
              { frequency: '每6小时1次', multiplier: 4, name: 'q6h' },
              { frequency: '每8小时1次', multiplier: 3, name: 'q8h' },
              { frequency: '每12小时1次', multiplier: 2, name: 'q12h' },
          ],
          //所有键的值
          columnHeader:[],
    
          // 导入的excel的数据
          excelData:[],
    
          // 表格显示列
          checkboxTableColumn:[],
    
          //表格数据
          tableData:[],
    
          // 当前分页
          upcurrentPage:1,
    
          // 每页显示数量
          uppageSize:10,
    
          // 数据总数
          uptotal:0
        };
      },
      computed: {},
      methods: {
        handleChangedrugdata(selectionArr) {
          let _targetArr = []
          // 遍历已选择数据id
          selectionArr.map((item) => {
              _targetArr.push(item)
          })
          this.Changedrugdata = _targetArr
          console.log(this.Changedrugdata)
        },
        debounce(func, delay) {
          clearTimeout(this.timer);
          this.timer = setTimeout(func, delay);
        },
        //防抖
        searchhistoryInput() {
          this.debounce(this.searchWarehousingStatementhistory, 1000);
        },
        searchhistoryblur() {
          if(this.searchdrugdatatext === ''){
            this.searchWarehousingStatementhistory()
          }
        },
        searchWarehousingStatementhistory(){
          const data = {
            currentPage: this.drugdatacurrentPage,
            pageSize: this.drugdatapageSize,
            search:this.searchdrugdatatext
          };
          this.$api.goods.searchWarehousingStatementhistory(data).then((res) => {
            this.drugdatacurrentPage = res.data.pagination.currentPage;
            this.drugdatapageSize = res.data.pagination.pageSize;
            this.drugdatatotal = res.data.pagination.total;
            this.items = res.data.data;
          });
        },
        getLabel(type) {
          const matchedOption = this.goodstypeoptions.find(option => option.value === type);
          return matchedOption ? matchedOption.label : '';
        },
        
        opendrugdatadialog(data){
          this.drugdataFormVisible = true
          this.drugdataForm = data;
        },
        opendialog(){
          this.drugdataFormVisible = true
          this.drugdataForm = {}
        },
        clocedialog(){
          this.dialogFormVisible = false;
          this.dialogTableVisible = false
          this.drugdataFormVisible = false
        },
        deleteWarehousingdrugdatahistory(data){
          this.$api.goods.deleteWarehousingdrugdatahistory(data).then((res) => {
            if(res.data.code === 200){
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.loadItems();
            }else{
              this.$message({
                message: res.data.msg,
                type: "warning",
              });
            }
          })
        },
        handlePageChange(page) {
          this.currentPage = page;
          this.loadItems();
        },
        handlePageSizeChange(pageSize) {
          this.pageSize = pageSize;
          this.loadItems();
        },
        loadItems() {
          const data = {
            currentPage: this.currentPage,
            pageSize: this.pageSize,
          };
          this.$api.goods.Warehousingdrugdatahistory(data).then((res) => {
            this.currentPage = res.data.pagination.currentPage;
            this.pageSize = res.data.pagination.pageSize;
            this.total = res.data.pagination.total;
            this.items = res.data.data;
          });
        },
        formatTime(row,column){
            let data = row[column.property]
            let dtime = new Date(data)
            const year = dtime.getFullYear()
            let month = dtime.getMonth() + 1
            if (month < 10) {
                month = '0' + month
            }
            let day = dtime.getDate()
            if (day < 10) {
                day = '0' + day
            }
            let hour = dtime.getHours()
            if (hour < 10) {
                hour = '0' + hour
            }
            let minute = dtime.getMinutes()
            if (minute < 10) {
                minute = '0' + minute
            }
            let second = dtime.getSeconds()
            if (second < 10) {
                second = '0' + second
            }
            return year+ '-' + month+ '-' + day
        }
      },
      created() {
        this.loadItems();
      },
      computed: {
      }
    };
    </script>
    <style>
    .mr30{
      margin-right: 30px;
    }
    .colorff2222{
      color: #ff2222;
    }
    .justifybetween{
      justify-content: space-between;
    }
    .aligncenter{
      align-items: center;
    }
    .ExpirationDate .el-input__inner{
      width: 70%;
    }
    .GenerationDate .el-input__inner{
      width: 70%;
    }
    .searchdrugdata .pagination{
      display: flex;
      justify-content: center;
      padding: 20px;
    }
    .searchdrugdata .el-tabs__content {
      overflow: auto;
      position: relative;
    }
    .mb5{
      margin-bottom: 5px;
    }
    .colorgrad{
      color: #cccccc;
    }
    .searchdrugdata .datalistbax{
      min-height: 588px;
      max-height: 588px; 
    }
    .searchdrugdata .el-tabs__header {
      padding: 0;
      margin: 0;
    }
    .ml30{
      margin-left: 30px;
    }
    .coloradd{
      color: #409EFF;
    }
    .text-overflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .ml60{
      margin-left: 60px;
    }
    .flex_row_space-between_center{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .pd20{
      padding: 20px;
    }
    .searchdrugdata .el-tabs--card>.el-tabs__header .el-tabs__nav {
      border: none;
      border-bottom: none;
      border-radius: 0;
    }
    .searchdrugdata .el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(n+1) {
      padding-left: 20px;
      border-right: 1px solid #E4E7ED;
      border-left: none;
    }
    .searchdrugdata .el-card__body {
      padding: 0!important;
    }
    .drugdata .el-table__body-wrapper{
      min-height: 550px;
    }
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 250px;
    }
    .w250{
      width: 250px;
    }
    .w120{
      width: 120px;
    }
    .flex{
      display: flex;
    }
    .justifyend{
      justify-content: end;
    }
    button:focus {
        outline: none;
    }
    .text {
      font-size: 14px;
    }
    
    .item {
      margin-bottom: 18px;
    }
    
    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
    }
    .clearfix:after {
      clear: both;
    }
    
    .box-card {
      width: 100%;
    }
    .w50{
      width:50%;
    }
    .goodsForm{
      display: flex;
      flex-wrap: wrap;
    }
    .goodsForm .el-form-item{
      width:30%;
    }
    .ishover:hover{
      background-color: #f5f5f5;
    }
    .w105{
      width: 105px;
    }
    .alignright{
      text-align: right;
    }
    .pd5{
      padding: 5px;
    }
    .pd10{
      padding: 10px;
    }
    </style>
    